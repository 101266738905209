<template>
  <AuthSlot>
    <template #title>Enter your email address to reset your password </template>
    <template #form>
      <validation-observer
        ref="simpleRules"
        #default="{ invalid }"
        v-if="!emailSent"
      >
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="validationForm"
        >
          <b-form-group class="mb-2">
            <div class="d-flex justify-content-between text-white">
              <label for="login-email" class="label-login">Email</label>
            </div>

            <validation-provider
              #default="{ errors }"
              name="Email"
              vid="email"
              rules="required|email"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend>
                  <div class="input-group-text">
                    <feather-icon icon="MailIcon" size="18"></feather-icon>
                  </div>
                </b-input-group-prepend>

                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  size="lg"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@example.com"
                />
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row v-if="!loading">
            <b-col cols="6">
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Send reset link
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button type="button" block @click="goToLogin">
                Cancel
              </b-button>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col cols="6">
              <b-button variant="primary" disabled block>
                <b-spinner small />
                Loading...
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button type="button" disabled block> Cancel </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <div v-else>
        <div class="text-center">
          <feather-icon
            icon="CheckCircleIcon"
            size="100"
            class="text-success"
          ></feather-icon>
        </div>
        <h4 class="text-center mt-2">Email Sent!</h4>
        <p class="text-center">
          We have sent you an email with a link to reset your password.
        </p>
        <b-row class="justify-content-center no-gutters">
          <b-col cols="10" md="12" lg="10" xl="10" sm="12" xs="12">
            <b-button type="button" block @click="goToLogin" :class="theme === 'dark' ? 'btn-dark' : 'btn-light'">
              Back to Login
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </AuthSlot>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
/* eslint-disable global-require */
import AuthSlot from "@/views/pages/authentication/AuthSlot.vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    AuthSlot,
  },
  data() {
    return {
      userEmail: "",
      required,
      email,
      loading: false,
      emailSent: false,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "auth-login" });
    },
    async validationForm() {
      try {
        this.loading = true;
        const validation = await this.$refs.simpleRules.validate();

        if (validation) {
          this.disable = true;
          const response = await useJwt.forgotPassword({
            email: this.userEmail,
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "EditIcon",
              variant: "success",
              text: response.data.msg,
            },
          });
          this.$refs.simpleRules.reset();
          this.loading = false;
          this.disable = false;
          this.emailSent = true;
        }
      } catch (error) {
        this.loading = false;
        this.disable = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "EditIcon",
            variant: "danger",
            // text: error,
            text: "Email not found"
          },
        });

        console.log(error);
      }
    },
  },
};
</script>